import { getMicroFeDependency } from "./deps-map";

// For the deployment Micro frontend host need to be configured at runtime.
// it is done with with the help of `external-remotes-plugin` package.
// Above mentioned package is solution from module federation team
// Ref - https://github.com/module-federation/module-federation-examples/issues/566
window.eyviaHost = getMicroFeDependency(window.location.host);
window.tprpHost = getMicroFeDependency(window.location.host);

import("./bootstrap");
